.registration-form-container {
    width: 50%;
  }

  .registration-response.success {
    color: green;
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
    border: 2px solid green;
    border-radius: 10px;
  }

  .registration-response.error {
    color: red;
    font-size: 1.5em;
    text-align: center;
    padding: 20px;
    border: 2px solid red;
    border-radius: 10px;
  }

/* Accesibility */

/* The sr-only class is used to hide content visually but still make it accessible to screen readers. */

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  @media only screen and ((min-width: 400px)) {
    .registration-form {
        width: 375px;
    }
  }
