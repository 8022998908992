#reg-page-wrapper {
  background-image: url(../../assets/img/reg_page_upper_bg.png);
  background-size: contain;
  background-repeat: no-repeat;
}

#take-surveys {
  font-weight: 900 !important;
  color: #666666;
}

#get-cards {
  font-weight: 900 !important;
}

.top-row-left.col input {
  color: #666666 !important;
}

.top-row-left.col select {
  color: #666666 !important;
}

.form-control {
  margin: 0 0 10px 0 !important;

}

.terms-field-wrapper {
  background: white;
  border-radius: 20px;
  padding: 20px 11px 0px 10px;
  font-size: 12px;
  margin: 0 0 20px 0 !important;
}

.reg-btn {
  width: 200px !important;
  height: 30px !important;
  color: #ffffff !important;
  font-size: 16px !important;
  border: solid 1px #75c6e0 !important;
  border-radius: 20px !important;
  background-color: #75c6e0 !important;
  font-size: 12px !important;
  text-align: center !important;
  font-weight: 600 !important;
  font-family: Lexend Deca !important;
  padding: 0rem !important;
  margin: 10px 0 0 0;
}

.placeholder-text,
.row-content {
  padding: 20px;
}

.page-content {
  padding: 20px;
  margin-right: auto !important;
}

.top-row-right {
  padding: 50px;
  /* border: 1px solid #ccc; */
  background-image: url(../../assets/img/top-row-right-dog.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.top-row-left {
  padding: 70px 110px 70px 110px;
  /* border: 1px solid #ccc; */
  justify-content: center;
}

.top-row-left img {
  width: 250px;
  margin: 10px 0 50px 15px;
}

.top-row-left p {
  margin: 0 0 20px 15px;
}

.top-row-left h3 {
  margin: 0 0 20px 15px;
}

.second-row {
  padding: 40px 40px 10px 40px;
  margin-top: 50px !important;
  /* border: 1px solid #ccc; */
  justify-content: center;
  text-align: center;
  vertical-align: baseline;
}

.icon-box {
  height: 150px;
  width: 150px;
}

.icon-box-row {
  height: 300px;
  vertical-align: text-bottom !important;
  justify-content: center;
  text-align: center;
}

.icon-box-row h4 {
  padding-top: 160px;
}

.icon-box-row {
  margin-top: 40px !important;
}

.icon-box-row p {
  padding: 0 90px 0 90px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.second-row .col {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.join-box {
  background-image: url(../../assets/img/join-icon.png);
}

.par-box {
  background-image: url(../../assets/img/participate-icon.png);
}

.earn-box {
  background-image: url(../../assets/img/earn-icon.png);
}

.third-row {
  padding: 30px 20px 10px 0;
  margin-top: 50px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: baseline;
}

.gift-card-row {
  margin-top: 40px !important;
}

.fourth-row {
  padding: 30px 20px 10px 0;
  margin-top: 50px !important;
  /* border: 1px solid #ccc; */
  display: flex;
  justify-content: center;

  text-align: center;
  vertical-align: baseline;
}

.why-join-row {
  margin-top: 40px !important;
}

.card-box {
  height: 138px;
  width: 220px;
  margin: 0 5px 0 5px;
}

.card-best-buy {
  background-image: url(../../assets/img/gift-card-bestbuy.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card-apple {
  background-image: url(../../assets/img/gift-card-apple.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card-wings {
  background-image: url(../../assets/img/gift-card-wings.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card-target {
  background-image: url(../../assets/img/gift-card-target.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card-starbucks {
  background-image: url(../../assets/img/gift-card-starbucks.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.card-amazon {
  background-image: url(../../assets/img/gift-card-amazon.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* WHY JOIN ROW */

.why-join-row {
  height: 300px;
  vertical-align: text-bottom !important;
  justify-content: center;
  text-align: center;
}

.why-join-row h4 {
  padding: 175px 50px 0 50px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.icon-engage {
  background-image: url(../../assets/img/icon-engagement.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-refer {
  background-image: url(../../assets/img/icon-process.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-quick {
  background-image: url(../../assets/img/icon-refer.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-giveaway {
  background-image: url(../../assets/img/icon-giveaway.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/*# .fourth-row icon boxes  */
/*# Layout for Devices >= 1000px and <= 1200px */

@media only screen and ((min-width: 1000px) and (max-width: 1200px)) {
  .icon-engage {
    max-width: 125px !important;
    margin: 0 40px 0 40px !important;
  }

  .icon-engage h4 {
    font-size: 20px !important;
  }

  .icon-refer {
    max-width: 125px !important;
    margin: 0 40px 0 40px !important;
  }

  .icon-refer h4 {
    font-size: 20px !important;
  }

  .icon-quick {
    max-width: 125px !important;
    margin: 0 40px 0 40px !important;
  }

  .icon-quick h4 {
    font-size: 20px !important;
  }

  .icon-giveaway {
    max-width: 125px !important;
    margin: 0 40px 0 40px !important;
  }

  .icon-giveaway h4 {
    font-size: 20px !important;
  }
}

/*# .fourth-row icon boxes  */
/*# Layout for Devices >= 700px and <= 999px */

@media only screen and ((min-width: 800px) and (max-width: 999px)) {
  .fourth-row {
    max-width: 100% !important;
  }
  .icon-engage {
    max-width: 115px !important;
    margin: 0 30px 0 30px !important;
  }

  .icon-engage h4 {
    font-size: 20px !important;
  }

  .icon-refer {
    max-width: 115px !important;
    margin: 0 30px 0 30px !important;
  }

  .icon-refer h4 {
    font-size: 20px !important;
  }

  .icon-quick {
    max-width: 115px !important;
    margin: 0 30px 0 30px !important;
  }

  .icon-quick h4 {
    font-size: 20px !important;
  }

  .icon-giveaway {
    max-width: 115px !important;
    margin: 0 30px 0 30px !important;
  }

  .icon-giveaway h4 {
    font-size: 20px !important;
  }
}

/*# .second-row icon boxes  */
/*# Layout for Devices >= 800px and <= 1000px */

@media only screen and ((min-width: 800px) and (max-width: 1000px)) {
  .icon-box-row p {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .icon-box-row h4 {
    padding: 150px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .join-box {
    max-width: 125px !important;
    margin: 0 50px 0 50px !important;
  }

  .par-box {
    max-width: 125px !important;
    margin: 0 50px 0 50px !important;
  }

  .earn-box {
    max-width: 125px !important;
    margin: 0 50px 0 50px !important;
  }
}

@media only screen and ((max-width: 800px)) {
  .top-row {
    flex-direction: column;
  }
  .top-row-right {
    padding: 200px;
  }

  .icon-box-row p {
    padding: 0px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .icon-box-row h4 {
    padding: 150px 0px 0px 0px;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .join-box {
    min-width: 125px;
    margin: 0 50px 150px 0 !important;
  }

  .par-box {
    min-width: 125px;
    margin: 0 50px 150px 0 !important;
  }

  .earn-box {
    min-width: 125px;
    margin: 0 50px 150px 0 !important;
  }

  .third-row .row {
    display: block !important;
  }

  .third-row {
    margin-top: 600px !important;
  }

  .container {
    max-width: none !important;
  }

  .card-best-buy {
    width: 150px !important;
    margin: auto !important;
  }
  .card-apple {
    width: 150px !important;
    margin: auto !important;
  }
  .card-wings {
    width: 150px !important;
    margin: auto !important;
  }
  .card-target {
    width: 150px !important;
    margin: auto !important;
  }
  .card-starbucks {
    width: 150px !important;
    margin: auto !important;
  }

  .card-amazon {
    width: 150px !important;
    margin: auto !important;
  }

  .second-row .row {
    display: block !important;
  }
}

/*# .fourth-row icon boxes  */
/*# Layout for Devices >= 500px and <= 800px */

@media only screen and ((max-width: 800px)) {
  .fourth-row {
    max-width: 100% !important;
  }
  .icon-engage {
    margin-bottom: 70px !important;
  }

  .icon-engage h4 {
    font-size: 20px !important;
  }

  .icon-refer {
    margin-bottom: 70px !important;
  }

  .icon-refer h4 {
    font-size: 20px !important;
  }

  .icon-quick {
    margin-bottom: 70px !important;
  }

  .icon-quick h4 {
    font-size: 20px !important;
  }

  .icon-giveaway {
    margin-bottom: 70px !important;
  }

  .icon-giveaway h4 {
    font-size: 20px !important;
  }

  .why-join-row {
    display: block !important;
    flex-direction: column;
    height: 100%;
    margin: auto;
  }

  .icon-box h4 {
    padding: 150px 0px 0px 0px;
  }
}

/*# .fourth-row icon boxes  */
/*# Layout for Devices >= 500px and <= 800px */

@media only screen and ((max-width: 500px)) {
  .top-row-left {
    padding: 0px;
  }

  .join-box {
    min-width: 125px;
    margin: 0 50px 150px 0 !important;
  }

  .par-box {
    min-width: 125px;
    margin: 0 50px 150px 0 !important;
  }

  .earn-box {
    min-width: 125px;
    margin: 0 50px 150px 0 !important;
  }
}


@media only screen and ((min-width: 400px)) {
    .form-control {
        width: 350px !important;
      }

  }

@media only screen and ((max-width: 500px)) {
    .top-row-right {
        margin: 60px 0 0 0 !important;
      }

  }
